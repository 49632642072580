import * as React from 'react'
import { FieldValues, Path, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextFieldProps } from '@mui/material'
import { Checkbox } from '@controls/Checkbox'
import { FormTextFieldStyled } from '..'
import { FormTranslatedTextFieldStyled } from './FormTranslatedTextFieldStyled'

export type FormTranslatedTextFieldProps<TFieldValues extends FieldValues> = Omit<
  TextFieldProps,
  'name' | 'label' | 'component'
> & {
  form: UseFormReturn<TFieldValues>
  nameEng: Path<TFieldValues>
  nameEst: Path<TFieldValues>
  nameRus: Path<TFieldValues>
  label: string
  optional?: boolean
  onReset?: (isNull: boolean) => void
}

export const FormTranslatedTextField = <TFieldValues extends FieldValues>({
  form,
  nameEng,
  nameEst,
  nameRus,
  label,
  optional,
  onReset,
  disabled,
  ...textFieldProps
}: FormTranslatedTextFieldProps<TFieldValues>): React.ReactElement => {
  const { t } = useTranslation()
  const [isNull, setIsNull] = React.useState<boolean>(false)

  React.useEffect(() => {
    onReset?.(isNull)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNull])

  const disabledInternal = disabled || isNull
  return (
    <FormTranslatedTextFieldStyled>
      {optional && (
        <Checkbox
          checked={isNull}
          onChange={(e) => setIsNull(e.target.checked)}
          label={t('enums.inclusion_option.Inherit')}
        />
      )}
      {!isNull && (
        <>
          <FormTextFieldStyled
            name={nameEng}
            form={form}
            label={`${label} ${t('app.languages.en')}`}
            disabled={disabledInternal}
            {...textFieldProps}
          />
          <FormTextFieldStyled
            name={nameEst}
            form={form}
            label={`${label} ${t('app.languages.et')}`}
            disabled={disabledInternal}
            {...textFieldProps}
          />
          <FormTextFieldStyled
            name={nameRus}
            form={form}
            label={`${label} ${t('app.languages.ru')}`}
            disabled={disabledInternal}
            {...textFieldProps}
          />
        </>
      )}
    </FormTranslatedTextFieldStyled>
  )
}
