import styled, { css } from 'styled-components'

export const FormTranslatedTextFieldStyled = styled('fieldset')`
  ${({ theme }) => css`
    border-color: ${theme.colors.grey100};
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};
    display: flex;
    row-gap: ${theme.spacing(2)};
    flex-direction: column;
  `}
`
